import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.site_admin_group',
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_ADMIN']),
      search: 'site_admin_group.search_keyword',
      filter: {
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        description: row.description,
      }),
      displayMode: 'table',
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      table: {
        data: <TableDataType>[
          {
            key: 'name',
            label: 'data.name',
            type: 'text',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.name,
        },
      },
      metaAction: {
        create: {
          label: 'action.create',
          linkTarget: '_self',
          type: 'route',
          route: () => ({ name: 'site-admin-group-create' }),
        },
      },
      dataAction: {
        update: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          label: 'action.edit',
          route: row => ({ name: 'site-admin-group-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          removeTarget: row => row.name,
        },
      },
    }

  }
}

export default new listConfig()
